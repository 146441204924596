import React from 'react';

import { ILayout } from 'modules/layout';

import type { GatsbyBrowser } from 'gatsby';

import 'modules/theme/sass/index.scss';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const Layout =
    (element.type as React.FunctionComponent & ILayout).Layout ??
    React.Fragment;
  return <Layout {...props}>{element}</Layout>;
};
